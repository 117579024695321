import { Link, useLocation, useNavigate } from "@remix-run/react";
import axios, { CanceledError } from "axios";
import { type ComponentPropsWithoutRef, useEffect, useRef, useState } from "react";
import { RiSearchLine, RiShoppingCartFill } from "react-icons/ri";
import type { Product } from "~types/api/product.types";

import alternativeImage from "~/assets/images/fallback/main-image-fallback.png";
import { PriceWarningModal } from "~/components/modals/price-warning-modal";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/forms/input";
import { useAddToCart } from "~/hooks/use-add-to-cart";
import { useDebounce } from "~/hooks/use-debounce";
import { useOutsideClick } from "~/hooks/use-outside-click";
import { getCharacteristicValue } from "~/services/characteristics";
import { cn } from "~/utils/classnames";

import "./Searchbar.css";

interface SearchbarProps extends ComponentPropsWithoutRef<typeof Input> {
  isUserConnected: boolean;
}

export const Searchbar = ({ isUserConnected, className, ...props }: SearchbarProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<Product[] | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce<string>(searchValue, 300);

  const handleSearch = async (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const searchbarRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen);
  };

  useOutsideClick(searchbarRef, () => {
    setIsDropdownOpen(false);
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsPending(true);
    setIsDropdownOpen(true);

    const search = async () => {
      try {
        const searchResponse = await axios.get<{ data: Product[]; totalItems: number }>(
          `/product/${debouncedSearchValue}`,
          { signal }
        );
        setSearchSuggestions(searchResponse.data.data || []);
        setTotalItems(searchResponse.data.totalItems || 0);
        setIsPending(false);
      } catch (error) {
        if (error instanceof CanceledError) {
          console.log("Request aborted");
        } else {
          throw error;
        }
      }
    };

    if (debouncedSearchValue.length === 0) {
      setSearchSuggestions(null);
      setIsDropdownOpen(false);
    } else {
      search();
    }

    return () => {
      controller.abort();
    };
  }, [debouncedSearchValue]);

  return (
    <div key={location.pathname} ref={searchbarRef} className={cn("Searchbar", className)}>
      <Input
        className="Searchbar-input"
        icon={<RiSearchLine />}
        onIconClick={() => {
          setIsDropdownOpen(false);
          navigate(`/recherche?search=${searchValue}`);
        }}
        placeholder="Rechercher un produit"
        onKeyUp={handleSearch}
        ref={inputRef}
        {...props}
      />
      {isDropdownOpen ? (
        <div className="Searchbar-dropdown z-40">
          <div className="Searchbar-dropdown-container">
            {Array.isArray(searchSuggestions) && searchSuggestions.length > 0 && !isPending
              ? searchSuggestions.map((product) => {
                  const subheading = getCharacteristicValue(product, "sous-titre-vignette");
                  return (
                    <SearchbarItem
                      key={product.id}
                      product={product}
                      subheading={subheading || ""}
                      onClick={handleClick}
                      isUserConnected={isUserConnected}
                    />
                  );
                })
              : null}
            {isPending ? <p className="Searchbar-dropdown-noProductItem">Chargement</p> : null}
            {!isPending && searchSuggestions?.length === 0 ? (
              <p className="Searchbar-dropdown-noProductItem">Aucun produit trouvé</p>
            ) : null}
          </div>
          {searchSuggestions && searchSuggestions.length > 5 && !isPending ? (
            <Link
              to={`/recherche?search=${searchValue}`}
              className="Searchbar-dropdown-seeAllResults"
            >
              Voir les {totalItems} résultats
            </Link>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const SearchbarItem = ({
  product,
  subheading,
  onClick,
  isUserConnected,
}: {
  product: Product;
  subheading: string;
  onClick: () => void;
  isUserConnected: boolean;
}) => {
  const {
    cartFetcher,
    isCartFetcherLoading,
    isCartFetcherDone,
    isPriceWarningModalOpen,
    closePriceWarningModal,
  } = useAddToCart(product);

  return (
    <div className="Searchbar-dropdown-item">
      <PriceWarningModal
        open={isPriceWarningModalOpen}
        setOpen={closePriceWarningModal}
        product={product}
      />
      <img
        src={product.images[0]?.url || alternativeImage}
        alt={product.title}
        className="Searchbar-dropdown-item-image"
      />
      <Link className="Searchbar-dropdown-item-content" to={product.slug || ""} onClick={onClick}>
        <p className="Searchbar-dropdown-item-title">{product.title}</p>
        <p className="Searchbar-dropdown-item-subHeading">{subheading}</p>
      </Link>

      {isUserConnected ? (
        <cartFetcher.Form
          className="Searchbar-dropdown-item-form"
          method="post"
          action="/cart/actions?/addItem"
        >
          <input type="hidden" name="productId" value={product.id} />
          <input type="hidden" name="quantity" value={1} />
          <Button
            type="submit"
            loadingState={isCartFetcherLoading ? "loading" : isCartFetcherDone ? "success" : "idle"}
            icon={<RiShoppingCartFill />}
            iconOnly
            className="Searchbar-dropdown-item-addToCart"
          />
        </cartFetcher.Form>
      ) : null}
    </div>
  );
};
