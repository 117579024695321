import { useFetcher } from "@remix-run/react";
import type { Product } from "~types/api/product.types";

import { useManagedFetcher } from "~/hooks/use-managed-fetcher";
import { useModalAfterAction } from "~/hooks/use-modal";

export function useAddToCart(product: Product | null) {
  const cartFetcher = useFetcher<any>();

  const [isCartFetcherLoading, isCartFetcherDone] = useManagedFetcher({
    fetcher: cartFetcher,
    toastSuccessMessage: "Produit ajouté au panier",
  });

  const { isOpen: isPriceWarningModalOpen, close: closePriceWarningModal } = useModalAfterAction({
    fetcherDone: isCartFetcherDone,
    fetcherLoading: isCartFetcherLoading,
  });

  const isProductInRestocking =
    product?.stocks[0]?.etatStock === 3 || product?.stocks[0]?.etatStock === 4;

  return {
    cartFetcher,
    isCartFetcherLoading,
    isCartFetcherDone,
    isPriceWarningModalOpen: isProductInRestocking ? isPriceWarningModalOpen : false,
    closePriceWarningModal: isProductInRestocking ? closePriceWarningModal : () => {},
  };
}
