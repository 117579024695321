import type { Contact } from "~types/api/contact.types";

export const getContactRestrictionMessage = (contact: Contact | null) => {
  if (!contact) {
    return null;
  }
  if (
    contact.hasRestrictDisplayPrice &&
    contact.hasRestrictAccessDocuments &&
    contact.hasRestrictValidateCart
  ) {
    return "Vous n'êtes pas autorisé à visualiser le prix de nos produits, ni à passer commande sur le webshop.";
  }
  if (contact.hasRestrictDisplayPrice && contact.hasRestrictAccessDocuments) {
    return "Vous n'êtes pas autorisé à visualiser le prix de nos produits ni à accéder aux documents de vos commandes passées dans votre espace client.";
  }
  if (contact.hasRestrictDisplayPrice && contact.hasRestrictValidateCart) {
    return "Vous n'êtes pas autorisé à visualiser le prix de nos produits ni à passer commande sur le webshop.";
  }
  if (contact.hasRestrictAccessDocuments && contact.hasRestrictValidateCart) {
    return "Vous n'êtes pas autorisé à passer commande sur le webshop ni à accéder aux documents de vos commandes passées dans votre espace client.";
  }
  if (contact.hasRestrictDisplayPrice) {
    return "Vous n'êtes pas autorisé à visualiser le prix de nos produits.";
  }
  if (contact.hasRestrictAccessDocuments) {
    return "Vous n'êtes pas autorisé à accéder aux documents de vos commandes passées dans votre espace client.";
  }
  if (contact.hasRestrictValidateCart) {
    return "Vous n'êtes pas autorisé à passer commande sur le webshop.";
  }

  return null;
};
