import { useNavigation } from "@remix-run/react";
import { useEffect, useRef, useState } from "react";

import { cn } from "~/utils/classnames";

import "./GlobalLoading.css";

const GlobalLoading = () => {
  const navigation = useNavigation();
  const active = navigation.state !== "idle";

  const progressBarRef = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(true);

  useEffect(() => {
    if (!progressBarRef.current) return;
    if (active) setAnimationComplete(false);

    Promise.all(
      progressBarRef.current.getAnimations({ subtree: true }).map((anim) => anim.finished)
    )
      .then(() => {
        if (!active) setAnimationComplete(true);
      })
      .catch(() => null);
  }, [active]);

  return (
    <div
      role="progressbar"
      aria-valuetext={active ? "Loading" : undefined}
      aria-hidden={!active}
      className="GlobalLoading"
    >
      <span
        ref={progressBarRef}
        className={cn(
          "GlobalLoading-progress",
          navigation.state === "idle" && animationComplete && "GlobalLoading-progress--completed",
          navigation.state === "submitting" && "GlobalLoading-progress--submitting",
          navigation.state === "loading" && "GlobalLoading-progress--loading",
          navigation.state === "idle" && !animationComplete && "GlobalLoading-progress--idle"
        )}
      />
    </div>
  );
};

export { GlobalLoading };
