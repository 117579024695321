import { useLocation } from "@remix-run/react";
import { useEffect, useState } from "react";
import type { Contact } from "~types/api/contact.types";
import type { User } from "~types/api/user.types";

export const useExpiredPassword = ({
  user,
  contact,
}: {
  user: User | null;
  contact: Contact | null;
}) => {
  const location = useLocation();

  const [isExpiredPasswordModalOpen, setIsExpiredPasswordModalOpen] = useState(false);
  const [isPasswordExpiresSoonModalOpen, setIsPasswordExpiresSoonModalOpen] = useState(false);
  const [isPasswordExpiresSoonModalSeen, setIsPasswordExpiresSoonModalSeen] = useState(true);

  useEffect(() => {
    if (location.pathname && (!user || !contact)) {
      window.sessionStorage.removeItem("passwordExpiresSoonModalSeen");
    }
    setIsPasswordExpiresSoonModalSeen(
      window.sessionStorage.getItem("passwordExpiresSoonModalSeen") === "true"
    );
  }, [user, contact, location.pathname]);

  useEffect(() => {
    if (user && contact && contact.passwordExpired === true) {
      setIsExpiredPasswordModalOpen(true);
    } else {
      setIsExpiredPasswordModalOpen(false);
    }
  }, [user, contact, location, isPasswordExpiresSoonModalSeen]);

  useEffect(() => {
    if (user && contact?.passwordExpiresSoon === true && !isPasswordExpiresSoonModalSeen) {
      setIsPasswordExpiresSoonModalOpen(true);
    }
  }, [user, contact, isPasswordExpiresSoonModalSeen]);

  return {
    isExpiredPasswordModalOpen,
    isPasswordExpiresSoonModalOpen,
    setIsExpiredPasswordModalOpen,
    setIsPasswordExpiresSoonModalOpen,
    setIsPasswordExpiresSoonModalSeen,
  };
};
