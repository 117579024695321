import { withZod } from "@remix-validated-form/with-zod";
import { z } from "zod";
import { zfd } from "zod-form-data";

const changePasswordSchema = z
  .object({
    userId: zfd.numeric(),
    oldPassword: z.string(),
    newPassword: z
      .string()
      .min(
        8,
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, et un chiffre."
      )
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, et un chiffre."
      ),
    confirmNewPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: "La confirmation du mot de passe ne correspond pas.",
    path: ["confirmNewPassword"],
  });

export const changePasswordValidator = withZod(changePasswordSchema);

const changeForgottenPasswordSchema = z
  .object({
    userId: zfd.numeric(),
    token: z.string().min(1, "Pas de token trouvé."),
    password: z
      .string()
      .min(
        8,
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, et un chiffre."
      )
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, et un chiffre."
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "La confirmation du mot de passe ne correspond pas.",
    path: ["confirmPassword"],
  });

export const changeForgottenPasswordValidator = withZod(changeForgottenPasswordSchema);
