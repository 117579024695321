import { useEffect } from "react";
import type { RefObject } from "react";

type Handler = (event: MouseEvent) => void;

export const useOutsideClick = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  mouseEvent: "mousedown" | "mouseup" = "mousedown"
): void => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const el = ref.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };
    document.addEventListener(mouseEvent, handleOutsideClick);

    return () => {
      document.removeEventListener(mouseEvent, handleOutsideClick);
    };
  }, [handler, mouseEvent, ref]);
};
