import { useEffect, useState } from "react";

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState<"down" | "up" | null>(null);

  // Update scroll direction to down only when scroll is more than header height
  useEffect(() => {
    let lastScroll = window.scrollY;
    let ticking = false;

    const updateScrollDirection = () => {
      const currentScroll = window.scrollY;

      if (currentScroll > 106) {
        if (currentScroll - lastScroll > 0) {
          setScrollDirection("down");
        } else if (currentScroll - lastScroll < 0) {
          setScrollDirection("up");
        }
      } else {
        setScrollDirection(null);
      }

      lastScroll = currentScroll;
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDirection);
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollDirection]);

  return scrollDirection;
}
