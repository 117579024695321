import { useCallback, useEffect, useState } from "react";

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return {
    open,
    close,
    isOpen,
  };
}

export function useModalAfterAction({
  fetcherDone,
  fetcherLoading,
}: {
  fetcherDone: boolean | undefined;
  fetcherLoading: boolean | undefined;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAlreadyOpened, setIsAlreadyOpened] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (fetcherLoading) {
      setIsAlreadyOpened(false);
    } else if (fetcherDone && !isAlreadyOpened) {
      open();
      setIsAlreadyOpened(true);
    }
  }, [fetcherDone, fetcherLoading, isAlreadyOpened, open]);

  return {
    open,
    close,
    isOpen,
  };
}
