import { useMatches } from "@remix-run/react";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const useGlobalErrorToast = () => {
  const matches = useMatches();

  useEffect(() => {
    matches.forEach((match, index) => {
      if (
        match.data &&
        typeof match.data === "object" &&
        "globalError" in match.data &&
        match.data.globalError &&
        typeof match.data.globalError === "string"
      ) {
        toast.error(match.data.globalError, { toastId: `global-error-${match.id}-${index}` });
      }
    });
  }, [matches]);
};
