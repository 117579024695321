import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  type DialogProps,
  DialogTitle,
  type DialogTitleProps,
} from "@headlessui/react";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef } from "react";
import { RiAlertLine, RiCheckboxCircleLine, RiCloseLine, RiErrorWarningLine } from "react-icons/ri";

import { Text } from "~/components/ui/typography";
import { cn } from "~/utils/classnames";

const modalStyles = cva(
  [
    "relative w-full overflow-hidden rounded-xl bg-white p-4 text-left shadow-xl sm:my-8 sm:p-6",
    "transform transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95",
  ],
  {
    variants: {
      size: {
        sm: "sm:max-w-sm",
        md: "sm:max-w-md",
        lg: "sm:max-w-lg",
      },
    },
    defaultVariants: {
      size: "sm",
    },
  }
);

const iconStyles = cva(
  "mx-auto mb-3 flex size-12 items-center justify-center rounded-full sm:mb-5",
  {
    variants: {
      type: {
        neutral: "bg-neutral-100 text-neutral-600",
        success: "bg-success-100 text-success-600",
        warning: "bg-warning-100 text-warning-600",
        danger: "bg-danger-100 text-danger-600",
      },
    },
    defaultVariants: {
      type: "neutral",
    },
  }
);

export interface ModalProps
  extends VariantProps<typeof modalStyles>,
    Omit<DialogProps, "title" | "onClose"> {
  type?: "neutral" | "success" | "warning" | "danger";
  setOpen: (open: boolean) => void;
}

export function Modal({ type, size, open, setOpen, className, children }: ModalProps) {
  const Icon =
    type === "success"
      ? RiCheckboxCircleLine
      : type === "warning"
        ? RiAlertLine
        : type === "danger"
          ? RiErrorWarningLine
          : null;
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-40">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-grey-500 bg-opacity-60 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel transition className={cn(modalStyles({ size, className }))}>
            <CloseButton
              type="button"
              onClick={() => setOpen(false)}
              className="absolute right-0 top-0 rounded-md bg-white pr-4 pt-4 text-grey-400 hover:text-grey-500"
            >
              <span className="sr-only">Close</span>
              <RiCloseLine aria-hidden="true" className="h-6 w-6" />
            </CloseButton>

            {type && type !== "neutral" && Icon ? (
              <div className={cn(iconStyles({ type }))}>
                <Icon aria-hidden="true" className="h-6 w-6" />
              </div>
            ) : null}

            <div className="text-center">
              <>{children}</>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export function ModalTitle({ children, className, ...props }: DialogTitleProps) {
  return (
    <DialogTitle as="div" className={cn("text-lg font-semibold", className)} {...props}>
      {children}
    </DialogTitle>
  );
}

export function ModalDescription({ children, className, ...props }: ComponentPropsWithoutRef<"p">) {
  return (
    <Text as="p" size="base" muted className={cn("mt-2", className)} {...props}>
      {children}
    </Text>
  );
}

export function ModalContent({ children, className, ...props }: ComponentPropsWithoutRef<"div">) {
  return (
    <div className={cn("mt-5 sm:mt-6", className)} {...props}>
      {children}
    </div>
  );
}

export function ModalButtons({ children, className, ...props }: ComponentPropsWithoutRef<"div">) {
  return (
    <div
      className={cn("mt-5 flex flex-col-reverse gap-3 *:grow sm:mt-6 sm:flex-row", className)}
      {...props}
    >
      {children}
    </div>
  );
}
