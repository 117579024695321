import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { Form, useLocation, useNavigate } from "@remix-run/react";
import type { ComponentPropsWithoutRef } from "react";
import { Fragment, useState } from "react";
import { RiLogoutBoxLine, RiUser3Line } from "react-icons/ri";
import type { Contact } from "~types/api/contact.types";
import type { User } from "~types/api/user.types";

import { ChangeContactModal } from "~/components/ChangeContactModal";
import { Button } from "~/components/ui/button";
import { Text } from "~/components/ui/typography";
import { accountNavItems } from "~/config/navigation";
import { cn } from "~/utils/classnames";

interface UserMenuButtonProps extends ComponentPropsWithoutRef<typeof Menu> {
  user: User | null;
  contact: Contact | null;
}

export const UserMenuButton = ({ user, contact, className, ...props }: UserMenuButtonProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isContactSelectionOpen, setIsContactSelectionOpen] = useState(false);

  if (!user || !contact) {
    return (
      <Button
        onClick={() => {
          if (!user) {
            navigate(`/connexion?redirect=${location.pathname}`);
          } else if (!contact) {
            setIsContactSelectionOpen(true);
          }
          if (props.onClick) {
            props.onClick();
          }
        }}
        variant="outline"
        icon={<RiUser3Line />}
        className={cn(
          "max-lg:size-10 max-lg:px-0 max-lg:text-base [&>span]:max-lg:sr-only",
          className
        )}
      >
        Se connecter
      </Button>
    );
  }

  return (
    <>
      <Menu as="div" className={cn("block sm:relative", className)} {...props}>
        <MenuButton as={Fragment}>
          <Button
            onClick={() => {
              if (props.onClick) {
                props.onClick();
              }
            }}
            variant="outline"
            icon={<RiUser3Line />}
            className={cn(
              "max-lg:size-10 max-lg:px-0 max-lg:text-base [&>span]:max-lg:sr-only",
              className
            )}
          >
            Mon compte
          </Button>
        </MenuButton>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems
            unmount={false}
            className="absolute left-0 right-0 z-30 mt-2 w-max origin-top-right divide-y divide-grey-100 rounded-xl border border-grey-200 bg-white px-4 shadow sm:left-auto"
          >
            <div className="pb-1 pt-3">
              <Text className="font-medium">
                {contact.firstName} {contact.lastName}
              </Text>
              <Text size="sm">{contact.company?.name}</Text>
              <Text size="xs" muted className="mt-1">
                Code client: {contact.code}
              </Text>
            </div>

            <MenuItem as={Fragment}>
              {({ close, focus }) => (
                <div className="my-1">
                  <Button
                    onClick={() => {
                      setIsContactSelectionOpen(true);
                      close();
                    }}
                    size="xs"
                    variant="ghost"
                    className={cn(
                      focus && "bg-grey-100",
                      "my-1 flex w-full justify-end rounded-md"
                    )}
                  >
                    Changer d&apos;utilisateur
                  </Button>
                </div>
              )}
            </MenuItem>

            <div>
              {accountNavItems.map((item) => (
                <Fragment key={item.to}>
                  {item.children && item.children.length > 0 ? (
                    <Fragment key={item.to}>
                      <MenuItem as="div" className="">
                        {({ close, focus }) => (
                          <Button
                            as="link"
                            to={item.to || "/"}
                            onClick={() => close()}
                            variant="ghost"
                            size="default"
                            icon={item.icon ? <item.icon className="size-4" /> : null}
                            className={cn(
                              "my-1 flex justify-end rounded-md",
                              focus && "bg-grey-100"
                            )}
                          >
                            {item.label}
                          </Button>
                        )}
                      </MenuItem>
                      {/* <Disclosure as="div" className="md:hidden">
                        <DisclosureButton
                          as={Button}
                          variant="ghost"
                          size="default"
                          icon={item.icon ? <item.icon className="size-4" /> : null}
                          className={cn("my-1 flex justify-end rounded-md")}
                        >
                          {item.label}
                          <HiOutlineChevronDown className="ml-auto h-5 w-5 text-grey-400" />
                        </DisclosureButton>
                        <DisclosurePanel>
                          {item.children.map((subItem) => (
                            <MenuItem key={subItem.label}>
                              {({ focus }) => (
                                <Button
                                  as="link"
                                  to={subItem.to ?? ""}
                                  variant="ghost"
                                  size="default"
                                  icon={item.icon ? <item.icon className="size-4" /> : null}
                                  className={cn(
                                    focus ? "bg-grey-100 text-grey-800" : "text-grey-600",
                                    "flex items-center gap-4 py-3 pl-16 pr-4 text-sm font-medium transition-colors lg:hidden"
                                  )}
                                >
                                  {subItem.icon ? <item.icon className="size-4" /> : null}
                                  {subItem.label}
                                </Button>
                              )}
                            </MenuItem>
                          ))}
                        </DisclosurePanel>
                      </Disclosure> */}
                    </Fragment>
                  ) : (
                    <MenuItem key={item.to}>
                      {({ focus }) => (
                        <Button
                          as="link"
                          to={item.to || "/"}
                          variant="ghost"
                          size="default"
                          icon={item.icon ? <item.icon className="size-4" /> : null}
                          className={cn("my-1 flex justify-end rounded-md", focus && "bg-grey-100")}
                        >
                          {item.label}
                        </Button>
                      )}
                    </MenuItem>
                  )}
                </Fragment>
              ))}
            </div>

            <Form action={`/logout?redirect=${location.pathname}`} method="POST">
              <MenuItem>
                {({ focus }) => (
                  <Button
                    type="submit"
                    variant="ghost"
                    size="default"
                    icon={<RiLogoutBoxLine className="size-4" />}
                    className={cn(
                      "my-1 flex w-full justify-end rounded-md text-danger-600",
                      focus && "bg-grey-100"
                    )}
                  >
                    Déconnexion
                  </Button>
                )}
              </MenuItem>
            </Form>
          </MenuItems>
        </Transition>
      </Menu>
      {user.contacts ? (
        <ChangeContactModal
          activeContact={contact}
          contacts={user.contacts}
          open={isContactSelectionOpen}
          onClose={() => setIsContactSelectionOpen(false)}
        />
      ) : null}
    </>
  );
};
